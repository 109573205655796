import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueScrollTo from 'vue-scrollto';
import Nextviewbutton from './components/elements/NextViewButton.vue';
import { loadLocaleMessages } from './locales';
import createDebugTools from './debugTools'; // Import the debug tools
import axios from 'axios';
import iframeCss from './assets/style/iframe.scss?inline';
import { defineAsyncComponent } from 'vue';
import embedded from './utils/Embedded.js';
import OrderManager from './utils/OrderManager';

const App = defineAsyncComponent(() =>
    import('./App.vue')
);
const TopBar = defineAsyncComponent(() =>
    import('./components/elements/TopBar.vue')
);

if (window.Gifty === undefined) {
    window.Gifty = {};
}
window.Gifty.debug = createDebugTools(window.Gifty);

Vue.use(VueScrollTo);
Vue.use(VueI18n);
Vue.component('next-view-button', Nextviewbutton);
Vue.component('top-bar', TopBar);
Vue.prototype.$money = (value, useSymbol = true) => {
    if (value === 0) {
        return window.Gifty.app.$t('free');
    }
    const cents = value / 100;
    const calculated = cents.toFixed(2)
        .replace('.', ',');
    if (useSymbol) {
        return `€\u00A0${calculated}`;
    }
    return calculated;
};
Vue.filter('money', Vue.prototype.$money);

window.Gifty.open = () => {
    window.Gifty.EventBus.$emit('widget-open');
};
window.Gifty.close = () => {
    window.Gifty.EventBus.$emit('widget-close');
};
window.Gifty.toggle = () => {
    window.Gifty.EventBus.$emit('widget-toggle');
};
window.Gifty.state = function state() {
    return typeof window.Gifty.stateString === 'undefined' ? 'closed' : window.Gifty.stateString;
};
window.Gifty.logTestData = (data) => {
    window.Gifty.EventBus.$emit('log-test-data');
};
window.Gifty.setTestData = (data) => {
    window.Gifty.EventBus.$emit('set-test-data', data);
};
window.Gifty.addToCart = (product) => {
    window.Gifty.EventBus.$emit('add-to-cart', product);
};
window.Gifty.applyActionCode = (code) => {
    window.Gifty.EventBus.$emit('apply-action-code', code);
}

window.Gifty.EventBus = new Vue();
window.Gifty.axios = axios;

window.Gifty.debug.log('info', 'Global methods and EventBus initialized');


function getFirstBrowserLanguage() {
    // Check for overwritten language first
    if (window.Gifty.language) {
        window.Gifty.debug.log('info', `Using overwritten language: ${window.Gifty.language}`);

        switch(window.Gifty.language.toLowerCase()) {
            case 'en': return 'en-GB';
            case 'de': return 'de-DE';
            case 'fr': return 'fr-FR';
            case 'nl': return 'nl-NL';
            default: break;
        }
    }

    const nav = window.navigator;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    // Support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (const language of nav.languages) {
            if (language) {
                return language;
            }
        }
    }

    // Support for other well known properties in browsers
    for (const key of browserLanguagePropertyKeys) {
        const language = nav[key];
        if (language) {
            return language;
        }
    }

    return 'en-GB';
}

const browserLocale = getFirstBrowserLanguage();
window.Gifty.locale = browserLocale;
window.Gifty.axios.defaults.headers.common['Accept-Language'] = browserLocale;

window.Gifty.debug.log('info', `Locale set to: ${browserLocale}`);

export const i18n = new VueI18n({
    locale: browserLocale,
    fallbackLocale: 'en-GB',
    messages: {},
});

export async function setLocale(locale) {
    const messages = await loadLocaleMessages(locale);
    await i18n.setLocaleMessage(locale, messages);
    i18n.locale = locale;
}
setLocale(browserLocale);

window.Gifty.setKey = (wkey) => {
    if (!wkey) {
        window.Gifty.debug.log('error', 'Method setKey called with invalid key');
        return;
    }

    window.Gifty.key = wkey;
    window.Gifty.debug.log('info', `Widget initializing with key: ${wkey}`);

    // Remove toggle widget
    if (window.document.contains(window.document.getElementById('gifty-container'))) {
        window.document
            .getElementById('gifty-container')
            .remove();
    }

    // Remove embedded widget
    if (window.document.contains(window.document.getElementById('gifty-embedded'))) {
        const current = window.document.getElementById('gifty-embedded');
        const parent = current.parentNode;

        const placeholder = document.createElement('div');
        placeholder.dataset.gifty = '';

        parent.replaceChild(placeholder, current);
    }

    const orderManager = new OrderManager(window.Gifty.debug);
    window.Gifty.app = new Vue({
        template: '<App :order-manager="orderManager" />',
        components: { App, TopBar },
        data: {
            settings: {},
            orderManager: orderManager,
        },
        i18n,
        watch: {
            async settings(value) {
                this.mount();
                embedded.init(value, orderManager);
                window.Gifty.debug.log('info', 'Settings updated, widget mounted and embedded initialized');
            },
        },

        beforeCreate() {
            const url = `${import.meta.env.VITE_API_URL}/widget/${window.Gifty.key}?locale=${i18n.locale}&version=${import.meta.env.VITE_VERSION}`;
            window.Gifty.axios.get(url)
                .then(({ data }) => {
                    this.settings = data.data;
                })
                .catch((error) => {
                    window.Gifty.debug.log('error', 'Failed to fetch widget data', error);
                    console.error('[Gifty] The embed code is not found or recognized');
                });
        },

        methods: {
            mount() {
                if (this.settings['translations']) {
                    // Overwrite with server-side translations
                    Object.keys(this.settings['translations'])
                        .forEach((locale) => {
                            i18n.mergeLocaleMessage(locale, this.settings['translations'][locale]);
                        });
                    window.Gifty.debug.log('info', 'Server-side translations merged');
                }

                const el = {
                    container: window.document.createElement('div'),
                    style: window.document.createElement('style'),
                    css: iframeCss,
                };

                // Add stylesheets if not available yet
                if (window.document.contains(window.document.getElementById('gifty-stylesheet')) === false) {
                    // el.style.innerHTML = el.css.toString();
                    el.style.innerHTML = el.css.toString();
                    el.style.setAttribute('id', 'gifty-stylesheet');
                    window.document.head.appendChild(el.style);
                }

                window.document.body.appendChild(el.container);

                this.$mount(el.container);
            },
        },
    });
};

if(
    window.Gifty.app === undefined &&
    window.Gifty.key !== undefined
) {
    window.Gifty.setKey(window.Gifty.key);
}

window.Gifty.debug.log('info', 'Application instantiation complete');

export default window.Gifty;
