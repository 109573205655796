import Vue from 'vue';
import VueIframe from '../components/VueIframe.vue';
import Widget from '../components/Widget.vue';

export default class {
    static iframes = [];

    static init(settings, orderManager) {
        const iframes = window.parent.document.querySelectorAll('div[data-gifty]');
        if (iframes.length <= 0) {
            return false;
        }

        for (let i = 0; i < iframes.length; i += 1) {
            this.iframes.push(this.embeddedVue(iframes[i], settings, orderManager));
        }
        return true;
    }

    static embeddedVue(giftyIframe, settings, orderManager) {
        return new Vue({
            template: `
                        <vue-iframe id="gifty-embedded" cssFile="app">
                            <widget
                                :settings="settings"
                                :isEmbedded="true"
                                :orderManager="orderManager"
                            ></widget>
                        </vue-iframe>
            `,
            el: giftyIframe,
            components: {
                VueIframe, Widget,
            },
            data() {
                return {
                    settings,
                    orderManager
                };
            },
        });
    }
}
