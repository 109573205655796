<template>
    <div class="container" id="order">
        <div class="content">
            <top-bar @prev-view="prevView()" @close-widget="$emit('close-widget')"
                     :previous="true" ref="topbar"></top-bar>

            <h1><i class="icon-giftcard branding-color"></i>{{ $t('orderpage.title') }}</h1>
            <p>{{ subtitle }}</p>

            <transition name="slide" mode="out-in">
                <template>
                    <div v-if="customGiftcardView" key="1" class="custom-giftcard">
                        <h2>{{ $t('orderpage.helptext') }}</h2>
                        <amount-input @submit="setCustomGiftcard"></amount-input>
                        <p><a href="#" @click.prevent="customGiftcardView = false">{{ $t('cancel') }}</a></p>
                    </div>

                    <div v-if="!customGiftcardView" key="2">

                        <transition name="slide" mode="out-in">
                            <div class="choice-box" v-if="orderOption === 'choice'" key="choice">
                                <div class="form-group">
                                    <div class="card-group card-arrow">
                                        <div class="card" @click="orderOption = 'price_only'">
                                            <div class="card-button">
                                                <i class="icon-circle-euro branding-color"></i>
                                                <p>{{ $t('orderpage.amounts') }}</p>
                                            </div>
                                        </div>
                                        <div class="card" @click="orderOption = 'packages_only'">
                                            <div class="card-button">
                                                <i class="icon-circle-star branding-color"></i>
                                                <p>{{ $t('orderpage.arrangements') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="packages-box" v-else-if="orderOption === 'packages_only'" key="package_only">
                                <div v-for="giftcard_package in settings.giftcard_packages"
                                     :key="giftcard_package.id"
                                     class="package-box"
                                     :ref="`giftcard-${giftcard_package.id}`">
                                    <h2 class="title">{{ giftcard_package.title }}</h2>
                                    <p class="text"
                                       v-if="readMoreId !== giftcard_package.id && giftcard_package.description !== null">
                                        {{ giftcard_package.description | shorten }}
                                        <a class="more-info"
                                           v-if="giftcard_package.description.length > 115"
                                           @click="readMore(giftcard_package.id)">{{ $t('more') }}</a>
                                    </p>
                                    <p class="text"
                                       v-else-if="readMoreId === giftcard_package.id && giftcard_package.description !== null">
                                        <span style="white-space: pre-line">{{ giftcard_package.description }}</span>
                                        <br v-if="giftcard_package.description.length > 115"/>
                                        <a class="more-info"
                                           v-if="giftcard_package.description.length > 115"
                                           @click="readMore('', giftcard_package.id)">{{ $t('less') }}</a>
                                    </p>
                                    <div class="order-box">
                                        <span class="currency-symbol">€</span>
                                        <span
                                            class="price">{{ $money(giftcard_package.amount, false) }}</span>
                                        <button class="button button-primary branding-button-background"
                                                @click="addGiftcard(giftcard_package.amount, giftcard_package)">
                                            {{ $t('button.add') }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="price-box" v-else key="price_box">
                                <div class="form-group">
                                    <div class="card-group">
                                        <div v-for="suggestion in settings.suggestions" class="card" :key="suggestion"
                                             :class="{'card-disabled': existsGiftcard(suggestion)}">
                                            <div class="card-button" @click="addGiftcard(suggestion)"><em><span>€</span>
                                                {{
                                                    suggestion /
                                                    100
                                                }}</em></div>
                                        </div>
                                        <div class="card">
                                            <div class="card-button" @click="customGiftcardView = true">
                                                <em>
                                                    <small>{{ $t('other') }}</small>
                                                </em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <div class="form-group" v-show="order.giftcards.length && orderOption === orderOptionStart" ref="cart-items">
                            <div class="table">
                                <transition-group name="list" tag="div" class="list">
                                    <order-row class="list-item" :order="giftcard"
                                               v-for="(giftcard, index) in order.giftcards"
                                               :key="giftcard.value + index"
                                               @quantityChange="quantityChange(giftcard.value, giftcard.title)"
                                               @delete="deleteGiftcard(giftcard.value, giftcard.title, giftcard.package_id)"></order-row>
                                </transition-group>

                                <div class="row row-totals text-bold">
                                    <div class="col">{{ $t('total') }}</div>
                                    <div class="col">{{ giftcardsTotalValue | money }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </transition>
        </div>

        <next-view-button v-show="showNextViewButton"
                          ref="button"
                          :subtitle="orderValueAboveLimit"
                          :disabled="!viewValidates">
            {{ $t('next') }}
            <p slot="subtitle">{{ $t('orderpage.maximum', [$money(settings.order_max)]) }}</p>
        </next-view-button>
        <div ref="bottom"></div>
    </div>
</template>

<script>
import OrderRow from './OrderRow.vue';
import AmountInput from './../../elements/AmountInput.vue';

export default {
    data() {
        return {
            order: this.$parent.$data.order,
            settings: this.$parent.settings,
            customGiftcardView: false,
            orderOption: 'price_only',
            orderOptionStart: 'price_only',
            readMoreId: '',
        };
    },
    components: {
        OrderRow,
        AmountInput,
    },
    props: {
        scrollTo: {
            type: [String, Boolean],
            default: false,
        },
    },
    computed: {
        showNextViewButton() {
            return !this.customGiftcardView &&
                this.orderOption === this.orderOptionStart &&
                this.order.giftcards.length;
        },
        giftcardsTotalValue() {
            let total = 0;

            this.order.giftcards.forEach((order) => {
                total += (order.value * order.quantity);
            });

            return total;
        },
        orderValueAboveLimit() {
            return this.giftcardsTotalValue > this.settings.order_max;
        },
        viewValidates() {
            return this.order.giftcards.length && !this.orderValueAboveLimit;
        },
        subtitle() {
            switch (this.orderOptionStart) {
                case 'price_only':
                    return this.$t('orderpage.subtitle.amount');
                case 'packages_only':
                    return this.$t('orderpage.subtitle.arrangement');
                default:
                    return this.$t('orderpage.subtitle.all');

            }
        },
    },
    methods: {
        quantityChange(value, giftcardPackage = false) {
            let packageId = null;
            let title = null;

            if (giftcardPackage) {
                packageId = giftcardPackage.id;
                title = giftcardPackage.title;
            }

            const giftCard = this.order.giftcards.find(card => card.value === value && card.title === title);

            let sku = `gift-card-${this.settings.company_id}-${value}`;
            if (title) {
                sku = `package-${packageId}-${this.settings.company_id}-${value}`;
            }

            window.parent.Gifty.EventBus.$emit('product-added', {
                sku,
                product_id: sku,
                name: 'Gift Card',
                brand: this.settings.name,
                variant: packageId ? `Package: ${packageId}` : value,
                price: value / 100,
                currency: 'EUR',
                quantity: giftCard ? giftCard.quantity : 1,
                image_url: this.settings.preview_url_small,
            });
        },
        addGiftcard(value, giftcardPackage = false) {
            let type = 'gift-card';
            if (giftcardPackage) {
                type = 'gift-card-package';
            }

            const success = this.$parent.addGiftcard(type, giftcardPackage ? giftcardPackage.id : value)

            if(!success) {
                return;
            }

            this.quantityChange(value, giftcardPackage);
            this.orderOption = this.orderOptionStart;
            this.$nextTick(() => {
                this.$parent.$refs.scrollable.scrollTop = 0;
                this.scrollToButton();
            });
        },
        deleteGiftcard(value, title, packageId = false) {
            this.$parent.deleteGiftcard(value, packageId);
        },
        existsGiftcard(value, giftcardPackage = false) {
            let title = null;
            if (giftcardPackage) {
                title = giftcardPackage.title;
            }

            return this.order.giftcards.find(card => card.value === value && card.title === title);
        },
        setCustomGiftcard(value) {
            this.addGiftcard(value);
            this.customGiftcardView = false;
        },
        setOrderOption() {
            let option = '';
            if (this.settings.packages_only === 0 &&
                this.settings.giftcard_packages.length > 0) {
                option = 'choice';
            } else if (this.settings.packages_only === 1) {
                option = 'packages_only';
            } else if (this.settings.giftcard_packages.length === 0) {
                option = 'price_only';
            }
            this.orderOption = option;
            this.orderOptionStart = option;
        },
        prevView() {
            if (this.orderOption === this.orderOptionStart) {
                this.$emit('prev-view');
            } else {
                this.orderOption = 'choice';
            }
        },
        scrollToButton() {
            this.$nextTick(() => {
                this.$scrollTo(this.$refs.bottom, 2500, {
                    onDone: () => {
                    }, // Todo: Remove fix for https://github.com/rigor789/vue-scrollto/issues/140
                    container: this.$parent.$refs.scrollable,
                    force: false,
                });
            });
        },
        readMore(id, scrollTo = false) {
            this.readMoreId = id;
            let scroll = id;
            if (scrollTo) {
                scroll = scrollTo;
            }
            this.$nextTick(() => {
                this.$scrollTo(this.$refs[`giftcard-${scroll}`][0], 1000, {
                    container: this.$parent.$refs.scrollable,
                    offset: -8,
                    force: false,
                });
            });
        },
        scrollToPerform(ref) {
            if(!ref) return;

            if(this.$refs[ref] === undefined) {
                return;
            }

            this.$nextTick(() => {
                this.$parent.$refs.scrollable.scrollTop = this.$refs[ref].offsetTop;
            });

            this.$emit('set-scroll-to', false);
        },
    },

    filters: {
        shorten(value) {
            if (value === null) {
                return '';
            } else if (value.length > 115) {
                return `${value.substring(0, 114)}...`;
            }
            return value;
        },
    },

    created() {
        this.setOrderOption();
    },

    watch: {
        scrollTo(ref) {
           this.scrollToPerform(ref);
        },
    },

    mounted() {
        window.parent.Gifty.EventBus.$emit('shop-viewed');

        if(this.scrollTo) {
            this.scrollToPerform(this.scrollTo);
        }
    }
};
</script>
