export default class StripePayments {

    constructor(paymentBus, customerSecret, returnUrl) {
        this.stripe = paymentBus.stripe;
        this.paymentBus = paymentBus;
        this.customerSecret = customerSecret;
        this.returnUrl = returnUrl;
    }

    async confirmPayment(paymentMethod, billingDetails) {
        if (paymentMethod === 'ideal') {
            return this.iDEALPayment(billingDetails);
        }

        if (paymentMethod === 'bancontact') {
            return this.bancontactPayment(billingDetails);
        }

        if (paymentMethod === 'giropay') {
            return this.giropayPayment(billingDetails);
        }


        if (paymentMethod === 'sofort') {
            return this.sofortPayment(billingDetails, this.paymentBus.sofortCountry);
        }


        if (paymentMethod === 'klarna') {
            return this.klarnaPayment(billingDetails);
        }

        return this.cardPayment(billingDetails);
    }

    async cardPayment(billingDetails) {
        return new Promise((resolve, reject) => {
            this.stripe.confirmCardPayment(this.customerSecret, {
                payment_method: {
                    card: this.paymentBus.card,
                    billing_details: billingDetails,
                },
                return_url: this.returnUrl,
            }, { handleActions: false })
                .then(resolve)
                .catch(reject);
        });
    }

    async iDEALPayment(billingDetails) {
        return new Promise((resolve, reject) => {
            this.stripe.confirmIdealPayment(this.customerSecret, {
                payment_method: {
                    ideal: {
                        bank: this.paymentBus.iDEALBank,
                    },
                    billing_details: billingDetails,
                },
                return_url: this.returnUrl,
            }, { handleActions: false })
                .then(resolve)
                .catch(reject);
        });
    }

    async bancontactPayment(billingDetails) {
        return new Promise((resolve, reject) => {
            this.stripe.confirmBancontactPayment(this.customerSecret, {
                payment_method: {
                    billing_details: billingDetails,
                },
                return_url: this.returnUrl,
            }, { handleActions: false })
                .then(resolve)
                .catch(reject);
        });
    }

    async giropayPayment(billingDetails) {
        return new Promise((resolve, reject) => {
            this.stripe.confirmGiropayPayment(this.customerSecret, {
                payment_method: {
                    billing_details: billingDetails,
                },
                return_url: this.returnUrl,
            }, { handleActions: false })
                .then(resolve)
                .catch(reject);
        });
    }

    async sofortPayment(billingDetails, sofortCountry) {
        return new Promise((resolve, reject) => {
            this.stripe.confirmSofortPayment(this.customerSecret, {
                payment_method: {
                    sofort: {
                        country: sofortCountry,
                    },
                    billing_details: billingDetails,
                },
                return_url: this.returnUrl,
            }, { handleActions: false })
                .then(resolve)
                .catch(reject);
        });
    }

    async klarnaPayment(billingDetails) {
        return new Promise((resolve, reject) => {
            this.stripe.confirmKlarnaPayment(this.customerSecret, {
                payment_method: {
                    billing_details: billingDetails,
                },
                return_url: this.returnUrl,
            }, { handleActions: false })
                .then(resolve)
                .catch(reject);
        });
    }
}
