export class OrderManager {
    STATUS = {
        CREATED: 0,
        PENDING: 10,
        APPROVAL_PENDING: 20,
        PAID: 30,
        CANCELLED: 40,
        FAILED: 50,
        REJECTED: 60,
        EXPIRED: 70,
        REFUNDED: 80,
        RESERVED: 90,
    };

    constructor(debug) {
        this.debug = debug;
        this.backupOrder = null; // Active order data to continue ordering
        this.processingOrderId = null; // ID of the order in payment
        this.processingOrderStatus = null; // Status of the order in payment (from backend)
    }

    /**
     * Initialize order management by checking localStorage and URL parameters
     * @returns {Promise<{ processingOrderId: string|null, processingOrderStatus: Object|null }>}
     */
    async initialize() {
        // Check for active order ID in various sources
        const processingOrderId = this.getOrderIdFromSources();

        if (!processingOrderId) {
            this.debug.log('info', 'No active order ID found');
            return { processingOrderId: null, processingOrderStatus: null };
        }

        try {
            // Fetch order (payment) status
            const processingOrderStatus = await this.fetchOrderData(processingOrderId);

            this.processingOrderId = processingOrderId;
            this.processingOrderStatus = processingOrderStatus;

            this.debug.log('info', 'Order initialized successfully', {
                processingOrderId,
                processingOrderStatus
            });

            return {
                processingOrderId,
                processingOrderStatus
            };
        } catch (error) {
            this.debug.log('error', 'Failed to initialize order', { error });
            this.clearOrderData();
            return {
                processingOrderId: null,
                processingOrderStatus: null,
            };
        }
    }

    /**
     * Get order ID from various sources (localStorage, URL params)
     * @returns {string|null}
     */
    getOrderIdFromSources() {
        // Check localStorage first
        const storedOrderId = localStorage.getItem('gifty.activeOrder');
        if (storedOrderId) {
            this.debug.log('info', 'Found order ID in localStorage', { orderId: storedOrderId });
            return storedOrderId;
        }

        // Check URL parameters for test confirmation
        const urlParams = new URLSearchParams(window.location.search);
        const testConfirmation = urlParams.get('giftyTestConfirmation');
        if (testConfirmation) {
            this.debug.log('info', 'Found test confirmation order ID in URL', { orderId: testConfirmation });
            return testConfirmation;
        }

        return null;
    }

    /**
     * Fetch order data from API
     * @param {string} orderId
     * @returns {Promise<Object>}
     */
    async fetchOrderData(orderId) {
        const response = await window.Gifty.axios.get(`${import.meta.env.VITE_API_URL}/order/${orderId}`);
        return response.data;
    }

    /**
     * Get stored order details from sessionStorage
     * @returns {Object|null}
     */
    getBackupOrderData() {
        const storedOrder = sessionStorage.getItem('gifty.order');
        return storedOrder ? JSON.parse(storedOrder) : null;
    }

    /**
     * Clear all order related data
     */
    clearOrderData() {
        localStorage.removeItem('gifty.activeOrder'); // Order ID

        this.processingOrderId = null;
        this.processingOrderStatus = null;
    }

    /**
     * Clear order backup
     */
    clearBackupOrder() {
        sessionStorage.removeItem('gifty.order'); // Backup Order Data

        this.backupOrder = null;
    }

    async storeInitialOrder(orderData, orderId) {
        try {
            await localStorage.setItem('gifty.activeOrder', orderId);
            await sessionStorage.setItem('gifty.order', JSON.stringify(orderData));

            this.backupOrder = orderData;
            this.processingOrderId = orderId;

            this.debug.log('order', 'Initial order data stored', {
                orderId,
                orderData
            });
        } catch (error) {
            this.debug.log('error', 'Failed to store initial order data', {
                error: error.message
            });
            throw error;
        }
    }
    /**
     * Handle failed order state
     */
    handleFailedOrder() {
        this.debug.log('order', 'Handling failed order', {
            processingOrderId: this.processingOrderId,
        })

        // Clear the processing state
        this.processingOrderId = null;
        this.processingOrderStatus = null;

        // Set the backup order for retry functionality
        this.backupOrder = this.getBackupOrderData();

        // Remove the active order ID from storage
        localStorage.removeItem('gifty.activeOrder');
    }
}

export default OrderManager;
